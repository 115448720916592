.cardEmpleado{
    min-width: 210px;
    /* max-width: 210px; */
    border-radius: 8px;
    background-color: var(--color-blanco);
    padding: 1.5em;
    transition: all .3s ease-in-out;
    cursor: pointer;
    margin: 1em 0;
    /* font-size: 1rem!important; */
}

.cardEmpleado:hover{
    transform: scale(1.05);
}

.cardEmpleado img{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    object-fit: cover;
}

.cardEmpleado__nombre{
    font-family: var(--fuente-principal);
    font-size: 1em;
    color: var(--color-negro);
    font-weight: 400;
    margin-top: 1em;
}

.no_foto_perfil{
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: var(--color-gris);

    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--color-blanco);
    font-size: 1.5em;
    font-weight: bold;
}

/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {

    .cardEmpleado{
        min-width: auto;
    }

}