.globalCardsCorteSemanalBanco{
    background-color: var(--color-blanco);
    padding: 1em;
    border-radius: 8px;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.05), 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin: 3em 0;
}

.cardCorteSemanalBanco{
    min-width: 33%;
    border-left: 1px solid var(--color-gris-hover);
    padding:0 1em;
}

.cardCorteSemanalBanco--no-border-left{
    border-left: none;
}


@media screen and (max-width: 768px) {
    .globalCardsCorteSemanalBanco {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .cardCorteSemanalBanco {
      min-width: unset;
      width: 100%;
      border-left: none;
      padding-left: 0;
      /* text-align: right; */
    }
  
    .text-md-right {
      text-align: right;
    }
  }
  