.table{
    width: 100%;
    font-family: var(--fuente-principal);
    font-size: 0.875em;
    color: var(--color-negro);
    border: 1px solid var(--color-negro);
    border-radius: 8px;
    margin-top: 1em;
    text-align: left;
}

.table thead{
    height: 59px;
    vertical-align: middle;
}

.table thead tr th{
    padding: 1em;
}

.table>:not(caption)>*>* {
    border:0;
}

.table > tbody{
    vertical-align: middle;
}

.table__name{
    display: flex;
    align-items: center;
}

.table__name img{
    border-radius: 100%;
    height: 40px;
    width: 40px;
    object-fit: cover;
}

.table__nameDescription{
    margin-left: 1em;
    width: 100%;
}

.table__nameDescription small{
    color:var(--color-gris);
    font-weight: 300;
}

.table__fechaAlta small{
    color:var(--color-gris);
    font-weight: 300;
}

.table tbody th{
    width: 50%;
    padding: 1em;
}

.table-max-20 {
    width: 20%!important;
}
.table-max-30 {
    width: 30%!important;
}
.table-max-40 {
    width: 40%!important;
}

.table-null-no-wrap{
    white-space: inherit!important;
}

.table tbody td{
    padding: 1em;
    white-space: normal; /* Evita que el texto se envuelva */
    overflow: hidden; /* Oculta cualquier parte del texto que exceda el ancho */
    text-overflow: ellipsis;
    max-width: 30%;
}

.table tbody tr{
    height: 92px;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

.table__estatus{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.table__estatus span{
    background-color: var(--color-verde);
    border-radius: 100px;
    padding: 0.2em 1em;
    font-size: 11px;
    font-weight: bold;
    color: var(--color-blanco);
    cursor: default;
}

.table__estatus button{
    width: 20px;
    border: none;
    background-color: transparent;
    color:var(--color-gris);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.table__estatus-inactive{
    background-color: var(--color-rojo)!important;
}

.table__estatus-warning{
    background-color: var(--color-azul)!important;
    color: black;
}

.table td, .table th{
    vertical-align: middle;
}

.pedidos__imgTabla{
    object-fit: cover;
}

.table__bg-azul{
    background-color: rgba(0, 84, 140, 0.1);
}

.table__bg-verde{
    background-color: rgba(16, 140, 0, 0.1);
}

.table__bg-rojo{
    background-color: rgba(140, 0, 0, 0.1);
}

.table__bg-naranja{
    background-color: rgba(140, 72, 0, 0.1);
}

@media screen and (max-width: 575px) {
    .table-responsive-gpt {
        overflow-x: auto;
    }

    .table-responsive-gpt .table {
        width: 600px;
    }
}


/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {

    .table-responsive-gpt {
        overflow-x: auto;
    }

    .table-responsive-gpt .table {
        width: 100%;
    }

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {

    .table-responsive-gpt {
        overflow-x: auto;
    }

    .table-responsive-gpt .table {
        width: 600px;
    }
}
