.perfil__fotoPerfil{
    border: 2px dashed rgb(229, 229, 229);
    padding: 1em;
    text-align: center;
    margin: 2em 0;
    display: flex;
    align-items: center;
}

.perfil__imagen{
    height:150px;
    width: 150px;
    object-fit: cover;
    border-radius: 100%;
}

.perfil__cambiarFoto{
    color:var(--color-azul);
    cursor: pointer;
    margin-left:2em;
    text-align: left;
}

.perfil__cambiarFoto:hover{
    color:var(--color-secundario-hover)
}