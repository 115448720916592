@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root{
  --color-azul: #00548C;
  --color-azul-hover: #13679f;

  --color-gris: #878787;
  --color-gris-hover: #6d6d6d;

  --color-blanco-fondo: #F9F9F9;

  --color-negro: #232323;
  --color-blanco: #FFFF;
  --color-rojo:#D15253;
  --color-naranja-suave:#FBA772;
  --color-naranja-suave-hover:#F98F4D;
  --color-verde: #34802f;
  --color-verde-hover: #419a3a;

  --fuente-titulo: 'Prompt';
  --fuente-principal: 'Montserrat';
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: var(--fuente-principal);
  font-size: 14px;
}

body{
  background-color: var(--color-blanco-fondo);
}

/* Disable estilos de tabla bootstrap */
table {
  border-collapse: unset;
  border-spacing: 0;
}

textarea:focus, input:focus{
  outline: none;
}

h1, h2, h3, h4, h5{
  font-family: var(--fuente-titulo);
  margin: 0;
}

p{
  margin-bottom:0!important;
}

a{
  text-decoration: none;
  cursor: pointer;
  color: var(--color-azul);
}

a:hover{
  text-decoration: none;
}

textarea{
  height:108px!important;
  padding-top: 10px!important;
}

label{
  font-size: 0.9em;
}

.hidden{
  display: none!important;
}

.btn-azul{
  background-color: var(--color-azul)!important;
  color: var(--color-blanco)!important;
}

.btn-azul:hover{
  background-color: var(--color-azul-hover)!important;
  color: var(--color-blanco)!important
}

.btn-gris{
  background-color: var(--color-gris)!important;
  color: var(--color-blanco)!important;
}

.btn-gris:hover{
  background-color: var(--color-gris-hover)!important;
  color: var(--color-blanco)!important
}

.btn-verde{
  background-color: var(--color-verde)!important;
  color: var(--color-blanco)!important;
}

.btn-verde:hover{
  background-color: var(--color-verde-hover)!important;
  color: var(--color-blanco)!important
}

.btn-block{
  width: 100%;
}

.color-rojo{
  color: var(--color-rojo)!important;
}

.color-verde{
  color: var(--color-verde)!important;
}

.color-gris{
  color: var(--color-gris);
}

hr{
  height: 1px;
  background-color: #D0D0D0;
  border: none;
  margin: 2em 0;
}

.noMargin{
  margin: 0 !important;
}

.noPadding{
  padding: 0!important;
}

.input{
  width: 100% !important;
  height: 2.875em;
  border: 1px solid #232323;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.02), 0px 1px 3px rgba(50, 50, 93, 0.15);
  border-radius: 0.375em;
  padding: 0em 0.75em;
  font-size: 0.9em;
  transition: all 300ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  margin:0 0 1em 0;
  background-color: var(--color-blanco);
}

.input::placeholder{
  font-size: 0.9em;
}

.input:focus{
  border-color: var(--color-gris);
  -webkit-box-shadow: 0px 1px 11px -4px var(--color-gris); 
  box-shadow: 0px 1px 11px -4px var(--color-gris);
}

.flag{
  background-color: var(--color-verde);
  border-radius: 100px;
  padding: 0.2em 1em;
  font-size: 11px;
  font-weight: bold;
  color: var(--color-blanco);
  cursor: default;
  max-width: 80px;
  text-align: center;
}

.flag-inactive{
  background-color: var(--color-rojo)!important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link{
  background-color: var(--color-azul);
}

/* CLASES PARA HACER QUE EL MODAL SALGA JUSTO EN EL CENTRO */
.modal-dialog {
  height: 100vh !important;
  display: flex;
}

.modal-content {
  margin: auto !important;
  height: fit-content !important;
}

/* ******************************************************** */

/* LOADER */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--color-azul);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


/* Small devices (landscape phones, 576px and up)*/
@media (max-width: 575px) {

    .col-0{
        display: none;
    }

    .noPadding-xs{
        padding:0 !important;
    }

    .pt-xs{
        padding-top: 15px;
    }

    .noMargin-xs{
        margin:0 !important;
    }

    .mt-xs{
        margin-top: 15px;
    }

    section {
        padding: 16px!important;
    }

    .bootstrap-select .dropdown-menu li a span.text{
      font-size: 12px;
    }

}

/* Medium devices (tablets, 768px and up)*/
@media (max-width: 767px) and (min-width: 576px) {

    .col-sm-0{
        display: none;
    }

    .noPadding-sm{
        padding:0 !important;
    }

    .pt-sm{
        padding-top: 15px;
    }

    .noMargin-sm{
        margin:0 !important;
    }

    .mt-sm{
        margin-top: 15px;
    }

}

/* Large devices (desktops, 992px and up)*/
@media (max-width: 991px) and (min-width: 768px) {

    .col-md-0{
        display: none;
    }

    .noPadding-md{
        padding:0 !important;
    }

    .pt-md{
        padding-top: 15px;
    }

    .noMargin-md{
        margin:0 !important;
    }

    .mt-md{
        margin-top: 15px;
    }

}

/* Extra large devices (large desktops, 1200px and up)*/
@media (max-width: 1199px) and (min-width: 992px) {

    .col-lg-0{
        display: none;
    }

    .noPadding-lg{
        padding:0 !important;
    }

    .pt-lg{
        padding-top: 15px;
    }

    .noMargin-lg{
        margin:0 !important;
    }

    .mt-lg{
        margin-top: 15px;
    }

}

@media (min-width: 1200px){

    .col-xl-0{
        display: none;
    }

    .noPadding-xl{
        padding:0 !important;
    }

    .pt-xl{
        padding-top: 15px;
    }
    
    .txtTiempo{
        font-size: 12px;
    }
    
    .noMargin-xl{
        margin:0 !important;
    }
    
    .mt-xl{
        margin-top: 15px;
    }
}