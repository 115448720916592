.toggle-button input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-button label {
    cursor: pointer;
    text-indent: -9999px;
    width: 80px; /* Ajusta el ancho a tu preferencia */
    height: 40px; /* Ajusta la altura a tu preferencia */
    background: grey;
    display: block;
    border-radius: 20px; /* Ajusta el radio para hacerlo más o menos redondo */
    position: relative;
}

.toggle-button label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    width: 30px; /* Ajusta el tamaño del botón deslizante */
    height: 30px; /* Ajusta el tamaño del botón deslizante */
    background: #fff;
    border-radius: 15px; /* Ajusta el radio para hacerlo más o menos redondo */
    transition: 0.3s;
}

.toggle-button input:checked + label {
    background: var(--color-azul);
}

.toggle-button input:checked + label:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}

.toggle-button label:active:after {
    width: 40px; /* Ajusta el tamaño del botón deslizante al hacer clic */
}
